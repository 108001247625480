<template>
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark">All Merchant Account Change Request List</h1>
                    </div><!-- /.col -->
                    <div class="col-sm-6">
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <form v-on:keyup.enter="searchData">
                                    <div class="row">
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label for="business">Business Name</label>
                                                <input type="text" id="business" v-model="search.business" placeholder="Enter Business Name" class="form-control" />
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group" style="margin-top:28px;">
                                                <button class="btn btn-info btn-sm mr-2" type="submit" @click.prevent="searchData"><i class="fa fa-search"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="card-body mr-20">
                                <b-overlay :show="loader">
                                    <div class="table-responsive">
                                        <div style="width:100%; overflow-x:auto;">
                                            <table class="table table-bordered table-striped table-sm text-center">
                                                <thead>
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>Owner </th>
                                                        <th>Business Name</th>
                                                        <th>Address</th>
                                                        <th>Reg. Date</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tfoot>
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>Owner </th>
                                                        <th>Business Name</th>
                                                        <th>Address</th>
                                                        <th>Reg. Date</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </tfoot>
                                                <tbody>
                                                    <tr v-for="(item,key) in listData" :key="key">
                                                        <td>BHKM{{ item.id }}</td>
                                                        <td>{{ item.name }} <br/>{{ item.mobile }}</td>
                                                        <td>{{ item.business }}</td>
                                                        <td>{{ item.address }}</td>
                                                        <td>{{ item.created_at | dateformat }}</td>
                                                        <td>{{ getStatus(item.status) }}</td>
                                                        <td style="text-align:center;">                                    
                                                            <button class="btn btn-success btn-sm mr-1" @click="showAccountInfoModal(item.account, item.request)" title="Approve"><i class="fas fa-check"></i></button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="mt-3">
                                        <b-pagination
                                            v-model="pagination.currentPage"
                                            :per-page="pagination.perPage"
                                            :total-rows="pagination.totalRows"
                                            @input="searchData"
                                        />
                                    </div>
                                </b-overlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- /.container-fluid -->
        </section>

        <div v-if="accountInfoModal" class="modal fade show" style="display:block" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <b-overlay :show="loader">
                    <div class="modal-content">
                        <b-overlay :show="loader">
                            <div class="modal-header bg-info text-white">
                                <h5 class="modal-title text-center w-100">Update Account Information</h5>
                                <button type="button" class="close" aria-label="Close" @click="cancelModal">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                                    <form @submit.prevent="handleSubmit(approveRequest)">
                                        <div class="row">
                                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <h4>Current Information</h4>
                                                <h6><u>Bank Account</u> </h6>
                                                <div class="form-group">
                                                    <label for="bank_id">Bank </label>
                                                    <v-select name="bank_id"
                                                        v-model="form_edit.account.bank_id"
                                                        label="text"
                                                        :reduce="item => item.id"
                                                        :options= commonObj.bankList
                                                        :disabled="true"
                                                        :placeholder="$t('globalTrans.select')"
                                                    /> 
                                                </div>
                                                <div class="form-group">
                                                    <label for="bank_acc_name">Bank Account Name </label>
                                                    <input type="text" id="bank_acc_name" v-model="form_edit.account.bank_acc_name" :disabled="true" class="form-control" placeholder="Bank Account Name">
                                                </div>
                                                <div class="form-group">
                                                    <label for="bank_acc_no">Bank Account No </label>
                                                    <input type="text" id="bank_acc_no" v-model="form_edit.account.bank_acc_no" :disabled="true" class="form-control" placeholder="Bank Account No">
                                                </div>
                                                <div class="form-group">
                                                    <label for="bank_branch">Bank Branch Name </label>
                                                    <input type="text" id="bank_branch" v-model="form_edit.account.bank_branch" :disabled="true" class="form-control" placeholder="Bank Branch Name">
                                                </div>
                                                <div class="form-group">
                                                    <label for="bank_routing_no">Bank Routing No </label>
                                                    <input type="text" id="bank_routing_no" v-model="form_edit.account.bank_routing_no" :disabled="true" class="form-control" placeholder="Bank Routing No">
                                                </div>
                                                <h6><u>Others Account</u> </h6>
                                                <div class="form-group">
                                                    <label for="bkash_no">Bkash Account No </label>
                                                    <input type="text" id="bkash_no" v-model="form_edit.account.bkash_no" :disabled="true" class="form-control" placeholder="Bkash Account No">
                                                </div>
                                                <div class="form-group">
                                                    <label for="bkash_acc_type">Bkash Account Type </label>
                                                    <v-select name="bkash_acc_type"
                                                        v-model="form_edit.account.bkash_acc_type"
                                                        label="text"
                                                        :reduce="item => item.id"
                                                        :disabled="true"
                                                        :options= mobileWalletTypeList
                                                        :placeholder="$t('globalTrans.select')"
                                                    /> 
                                                </div>
                                                <div class="form-group">
                                                    <label for="nagad_no">Nagad Account No </label>
                                                    <input type="text" id="nagad_no" v-model="form_edit.account.nagad_no" :disabled="true" class="form-control" placeholder="Nagad Account No">
                                                </div>
                                                <div class="form-group">
                                                    <label for="nagad_acc_type">Nagad Account Type </label>
                                                    <v-select name="nagad_acc_type"
                                                        v-model="form_edit.account.nagad_acc_type"
                                                        label="text"
                                                        :reduce="item => item.id"
                                                        :disabled="true"
                                                        :options= mobileWalletTypeList
                                                        :placeholder="$t('globalTrans.select')"
                                                    /> 
                                                </div>
                                                <div class="form-group">
                                                    <label for="rocket_no">Rocket Account No </label>
                                                    <input type="text" id="rocket_no" v-model="form_edit.account.rocket_no" :disabled="true" class="form-control" placeholder="Rocket Account No">
                                                </div>
                                                <div class="form-group">
                                                    <label for="rocket_acc_type">Rocket Account Type </label>
                                                    <v-select name="rocket_acc_type"
                                                        v-model="form_edit.account.rocket_acc_type"
                                                        label="text"
                                                        :reduce="item => item.id"
                                                        :disabled="true"
                                                        :options= mobileWalletTypeList
                                                        :placeholder="$t('globalTrans.select')"
                                                    /> 
                                                </div>
                                            </div>   
                                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <h4>New Information</h4>
                                                <h6><u>Bank Account</u> </h6>
                                                <ValidationProvider name="Bank" vid="bank_d" v-slot="{errors}" rules="required">
                                                    <div class="form-group">
                                                        <label for="bank_id">Bank </label>
                                                        <v-select name="bank_id"
                                                            v-model="form_edit.request.bank_id"
                                                            label="text"
                                                            :reduce="item => item.id"
                                                            :options= commonObj.bankList
                                                            :placeholder="$t('globalTrans.select')"
                                                        /> 
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                    </div>
                                                </ValidationProvider>
                                                <ValidationProvider name="Bank Account Name" vid="bank_acc_name" v-slot="{errors}" rules="required">
                                                    <div class="form-group">
                                                        <label for="bank_acc_name">Bank Account Name </label>
                                                        <input type="text" id="bank_acc_name" v-model="form_edit.request.bank_acc_name" class="form-control" placeholder="Bank Account Name">
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                    </div>
                                                </ValidationProvider>
                                                <ValidationProvider name="Bank Account No" vid="bank_acc_no" v-slot="{errors}" rules="required">
                                                    <div class="form-group">
                                                        <label for="bank_acc_no">Bank Account No </label>
                                                        <input type="text" id="bank_acc_no" v-model="form_edit.request.bank_acc_no" class="form-control" placeholder="Bank Account No">
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                    </div>
                                                </ValidationProvider>
                                                <ValidationProvider name="Bank Branch Name" vid="bank_branch" v-slot="{errors}" rules="required">
                                                    <div class="form-group">
                                                        <label for="bank_branch">Bank Branch Name </label>
                                                        <input type="text" id="bank_branch" v-model="form_edit.request.bank_branch" class="form-control" placeholder="Bank Branch Name">
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                    </div>
                                                </ValidationProvider>
                                                <ValidationProvider name="Bank Routing No" vid="bank_routing_no" v-slot="{errors}" rules="required">
                                                    <div class="form-group">
                                                        <label for="bank_routing_no">Bank Routing No </label>
                                                        <input type="text" id="bank_routing_no" v-model="form_edit.request.bank_routing_no" class="form-control" placeholder="Bank Routing No">
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                    </div>
                                                </ValidationProvider>
                                                <h6><u>Others Account</u> </h6>
                                                <ValidationProvider name="Bkash Account" vid="bkash_no" v-slot="{errors}" rules="required">
                                                    <div class="form-group">
                                                        <label for="bkash_no">Bkash Account </label>
                                                        <input type="text" id="bkash_no" v-model="form_edit.request.bkash_no" class="form-control" placeholder="Bkash Account">
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                    </div>
                                                </ValidationProvider>
                                                <ValidationProvider name="Bkash Account Type" vid="bkash_acc_type" v-slot="{errors}" rules="required">
                                                    <div class="form-group">
                                                        <label for="bkash_acc_type">Bkash Account Type</label>
                                                        <v-select name="bkash_acc_type"
                                                            v-model="form_edit.request.bkash_acc_type"
                                                            label="text"
                                                            :reduce="item => item.id"
                                                            :options= mobileWalletTypeList
                                                            :placeholder="$t('globalTrans.select')"
                                                        /> 
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                    </div>
                                                </ValidationProvider>
                                                <ValidationProvider name="Nagad Account" vid="nagad_no" v-slot="{errors}" rules="required">
                                                    <div class="form-group">
                                                        <label for="nagad_no">Nagad Account </label>
                                                        <input type="text" id="nagad_no" v-model="form_edit.request.nagad_no" class="form-control" placeholder="Nagad Account">
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                    </div>
                                                </ValidationProvider>
                                                <ValidationProvider name="Nagad Account Type" vid="nagad_acc_type" v-slot="{errors}" rules="required">
                                                    <div class="form-group">
                                                        <label for="nagad_acc_type">Nagad Account Type</label>
                                                        <v-select name="nagad_acc_type"
                                                            v-model="form_edit.request.nagad_acc_type"
                                                            label="text"
                                                            :reduce="item => item.id"
                                                            :options= mobileWalletTypeList
                                                            :placeholder="$t('globalTrans.select')"
                                                        /> 
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                    </div>
                                                </ValidationProvider>
                                                <ValidationProvider name="Rocket Account" vid="rocket_no" v-slot="{errors}" rules="required">
                                                    <div class="form-group">
                                                        <label for="rocket_no">Rocket Account </label>
                                                        <input type="text" id="rocket_no" v-model="form_edit.request.rocket_no" class="form-control" placeholder="Rocket Account">
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                    </div>
                                                </ValidationProvider>
                                                <ValidationProvider name="Rocket Account Type" vid="rocket_acc_type" v-slot="{errors}" rules="required">
                                                    <div class="form-group">
                                                        <label for="rocket_acc_type">Rocket Account Type</label>
                                                        <v-select name="rocket_acc_type"
                                                            v-model="form_edit.request.rocket_acc_type"
                                                            label="text"
                                                            :reduce="item => item.id"
                                                            :options= mobileWalletTypeList
                                                            :placeholder="$t('globalTrans.select')"
                                                        /> 
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                    </div>
                                                </ValidationProvider>
                                            </div>   
                                        </div>
                                    </form>
                                </ValidationObserver>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-info" @click.prevent="approveRequest">Update</button>
                                <button type="button" class="btn btn-danger" @click="cancelModal">Cancel</button>
                            </div>
                        </b-overlay>
                    </div>
                </b-overlay>
            </div>
        </div>
    </div>
</template>
<script>
    import config from '@/config'
    export default {
        name:'IrregularMerchant',
        data () {
            return { 
                loader: false,   
                accountInfoModal: false,
                merchants:{
                    data:[]
                },
                search: {
                    business: '',
                },
                form_edit: {
                    account: null,
                    request: null
                },
                pagination: {
                    currentPage: 1,
                    totalRows: 0,
                    perPage: this.$store.state.commonObj.perPage,
                    slOffset: 1
                },
                errors: [],
                mobileWalletTypeList: [
                    { id: 1, text: 'Personal'},
                    { id: 2, text: 'Agent'},
                ]
            }
        },
        created () {
            this.loadData()
        },
        computed : {
            authUser () {
                return this.$store.state.authUser
            },
            stateReload () {
                return this.$store.state.stateReload
            },
            listData () {
                return this.$store.state.list
            },
            commonObj () {
                return this.$store.state.commonObj
            }
        },
        methods:{
            searchData () {
                this.loadData()
            },
            async loadData(){       
                this.loader = true 
                const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })       
                const response = await config.getData('/merchant/account-change-request', params)
                this.loader = false
                if (response.status == 200) {
                    this.$store.dispatch('setList', response.data.data)
                    this.paginationData(response.data)
                } else {
                    this.$store.dispatch('setList', [])
                }
            },
            showAccountInfoModal(account, request) {
                this.form_edit.account = account
                this.form_edit.request = request
                this.accountInfoModal = true
                document.body.classList.add("modal-open");
            },
            paginationData (data) {
                this.pagination.currentPage = data.current_page
                this.pagination.totalRows = data.total
                this.pagination.perPage = data.per_page
                this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
            },
            async approveRequest(){
                this.loader = true
                const response = await config.postData('/merchant/account-request-approve', this.form_edit.request)
                this.loader = false
                if (response.status == 201) {
                    this.accountInfoModal = false   
                    this.loadData()      
                    this.$toast.success({
                        title: 'Success',
                        message: response.message,
                        color: '#218838'
                    }) 
                } else {
                    this.$refs.form.setErrors(response.errors)
                    this.$toast.error({
                        title: 'Error',
                        message: response.message,
                        color: '#dc3545'
                    })   
                }
            },
            cancelModal(){
                this.accountInfoModal = false
                document.body.classList.remove("modal-open");
            },
            getStatus (status) {
                if (status == 1) {
                    return 'Pending'
                } else if (status == 2) {
                    return 'Approved'
                } else if (status == 3) {
                    return 'Incomplete'
                } else {
                    return 'Reject'
                }
            }
        }
    }
</script>
<style>
    .w-35{
        width:35px;
    }
</style>